/*!***********************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./src/styles/main.scss ***!
  \***********************************************************************************************************************************************************************/
@charset "UTF-8";
/*********** Flex ***********/
.flex0 {
  flex: 0;
  -ms-flex: 0;
}

.flex1, div[data-role=elements] div input[type=checkbox] + label, body.mobile div[data-role=elements] div input[type=radio] + label, body.mobile div[data-role=elements] div .input_wrapper, body.mobile div[data-role=elements] fieldset .field-checkbox input[type=checkbox] + label, body.mobile div[data-role=elements] fieldset .field-checkbox, body.mobile div[data-id=child_info-club_to_join-name] fieldset input, div[data-template=input_address] input,
div[data-template=input_address_kana] input,
div[data-template=input_building_info] input,
div[data-template=input_employee_name] input, body.mobile div[data-template=input_birthdate_in_japanese_calendar] input, body.mobile div[data-template=input_name] input,
body.mobile div[data-template=input_name_kana] input,
body.mobile div[data-template=input_zipcode] input, body.mobile div[data-template=input_email] input, body.mobile div[data-template=input_phone_number] input {
  flex: 1;
  -ms-flex: 1;
}

.flex2 {
  flex: 2;
  -ms-flex: 2;
}

.flex3 {
  flex: 3;
  -ms-flex: 3;
}

.flex4 {
  flex: 4;
  -ms-flex: 4;
}

.flex5 {
  flex: 5;
  -ms-flex: 5;
}

.flex6 {
  flex: 6;
  -ms-flex: 6;
}

.flex7 {
  flex: 7;
  -ms-flex: 7;
}

.flex8 {
  flex: 8;
  -ms-flex: 8;
}

.flex9 {
  flex: 9;
  -ms-flex: 9;
}

.flex10 {
  flex: 10;
  -ms-flex: 10;
}

.flex11 {
  flex: 11;
  -ms-flex: 11;
}

.flex12 {
  flex: 12;
  -ms-flex: 12;
}

.flex13 {
  flex: 13;
  -ms-flex: 13;
}

.flex14 {
  flex: 14;
  -ms-flex: 14;
}

.flex15 {
  flex: 15;
  -ms-flex: 15;
}

.flex16 {
  flex: 16;
  -ms-flex: 16;
}

.flex17 {
  flex: 17;
  -ms-flex: 17;
}

.flex18 {
  flex: 18;
  -ms-flex: 18;
}

.flex19 {
  flex: 19;
  -ms-flex: 19;
}

.flex20 {
  flex: 20;
  -ms-flex: 20;
}

.row, .radio_wrapper, body.mobile div[data-role=elements] div .input_wrapper, body.mobile div[data-role=elements] fieldset .field-checkbox, body.mobile div[data-id=child_info-club_to_join-name] fieldset, div[data-template=input_address],
div[data-template=input_address_kana],
div[data-template=input_building_info],
div[data-template=input_employee_name], body.mobile div[data-template=input_birthdate_in_japanese_calendar], body.mobile div[data-template=input_name],
body.mobile div[data-template=input_name_kana],
body.mobile div[data-template=input_zipcode], body.mobile div[data-template=input_email], body.mobile div[data-template=input_phone_number] {
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  position: relative;
}

.column, body.mobile div[data-role=elements] div.radio_wrapper, body.mobile div[data-role=elements] fieldset.checkbox_wrapper, div[data-id=extended_nursery-intend_to_use] div[data-role=elements] div.radio_wrapper {
  display: flex;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column;
}

.flex-center, div.input-number > div {
  display: flex;
  justify-content: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
}

.flex-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: start;
}

.flex-center-left, div.input-number {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-pack: start;
  -ms-flex-align: center;
}

.flex-center-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-pack: start;
  -ms-flex-align: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  display: -ms-flexbox;
  -ms-flex-pack: end;
  -ms-flex-align: end;
}

.flex-center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: end;
}

.flex-auto {
  flex-basis: auto;
  -ms-flex-preferred-size: auto;
}

/*********** Colors ***********/ /*

ブレイクポイントのメモ

* モバイル横は考慮しない
* 基本的スタイルはモバイル、タブレット、PCの3種類。
* iPad縦持ちはモバイルあつかい。

* モバイル =>アナログなし
* タブレット縦 => アナログなし
* タブレット横 => アナログあり
* PC => アナログあり

参考
https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
*/
#ua_debug {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.generated {
  flex: 1;
  -ms-flex: 1;
  height: 100%;
  overflow: scroll;
  background: #fafafa;
  -ms-overflow-style: scrollbar;
}
body.mobile .generated {
  height: auto;
  overflow: visible;
  width: 100%;
}
.generated header.head {
  background: #fff;
  position: fixed;
  z-index: 9999;
  width: 50%;
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  height: 48px;
  align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -ms-flex-pack: justify;
}
body.mobile .generated header.head {
  width: 100%;
  justify-content: flex-end;
}
@media screen and (orientation: portrait) {
  body.tablet .generated header.head {
    width: 100%;
  }
}
@media screen and (orientation: landscape) {
  body.tablet .generated header.head {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  body.desktop .generated header.head {
    width: 100%;
  }
}
.generated nav {
  padding-top: 48px;
  background: #fff;
  background-color: rgb(202, 203, 214);
  width: 100%;
  height: 48px;
  border-bottom: solid 1px #ddd;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  overflow-y: -moz-hidden-unscrollable;
  scrollbar-width: thin;
  scrollbar-color: #dfe4ff #fff;
}
.generated nav::-webkit-scrollbar {
  display: none;
}
body.mobile .generated nav {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.generated nav button {
  font-size: 13px;
  border-top: solid 1px #ddd;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  border-bottom: 0;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 6px 3px 0 0;
  padding: 0 15px;
  color: #707586;
  height: 42px;
}
body.mobile .generated nav button {
  margin: 6px 3px 0 0;
  display: inline-block;
}
.generated nav button[selected] {
  background-color: #d8dffa;
  border-color: #a2b4fb;
  color: #494E5D;
  font-weight: bold;
}
body.desktop .generated nav button[selected]:hover {
  background-color: #eff2fe;
  color: #494E5D;
}
body.desktop .generated nav button:hover {
  background-color: #EFF2FF;
  color: #3a61ff;
}
.generated h1 {
  margin: 0;
  margin-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: #444;
  flex: 1;
  -ms-flex: 1;
}
body.mobile .generated h1 {
  font-weight: bold;
}
@media screen and (orientation: portrait) {
  body.tablet .generated h1 {
    font-size: 140%;
  }
}

p {
  font-size: 15px;
}
h4 + p {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
}
body.mobile h4 + p {
  font-size: 15px;
  line-height: 1.6;
}
body.mobile .fieldWrapper p {
  font-size: 15px;
  line-height: 1.6;
}
p[data-role=errors] {
  background-color: #fff9ba;
  padding: 5px 16px;
  font-size: 80%;
  border-radius: 4px;
  border: solid 1px #eede24;
  margin-left: 0.5em;
}
p[data-role=description] {
  padding-left: 0.5em;
}
body.mobile p[data-role=description] {
  padding-left: 0;
}

.box > .fieldWrapper > p[data-role=description] {
  margin: 0 0 30px;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  height: 60px;
  border-radius: 3px;
}
body.mobile textarea {
  width: calc(100% - 16px);
  padding: 8px;
  -webkit-appearance: none;
  appearance: none;
}

.map {
  width: 100%;
  height: 400px;
}

*[data-role=description] {
  margin-bottom: 10px;
}
*[data-role=description] p {
  margin: 0;
  font-size: 14px;
}

div[data-template=field-element] {
  margin-bottom: 24px;
}
div[data-template=field-element]:last-child {
  margin-bottom: 10px;
}
div[data-template=field-element] > div {
  padding-left: 0.5em;
}
body.mobile div[data-template=field-element] > div {
  padding-left: 0;
}

body.mobile div[data-template=input_phone_number] {
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 6px;
  align-items: center;
  -ms-flex-align: center;
}
body.mobile div[data-template=input_phone_number] input {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 14px;
  text-align: center;
  width: 30px;
}

body.mobile div[data-template=input_email] {
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 6px;
  align-items: center;
  -ms-flex-align: center;
}
body.mobile div[data-template=input_email] input {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 14px;
  text-align: center;
  width: 30px;
}

body.mobile div[data-template=input_name],
body.mobile div[data-template=input_name_kana],
body.mobile div[data-template=input_zipcode] {
  align-items: center;
  -ms-flex-align: center;
}
body.mobile div[data-template=input_name] input,
body.mobile div[data-template=input_name_kana] input,
body.mobile div[data-template=input_zipcode] input {
  width: 30px;
}

body.mobile div[data-template=input_birthdate_in_japanese_calendar] {
  align-items: center;
  -ms-flex-align: center;
}
div[data-template=input_birthdate_in_japanese_calendar] input {
  width: 2em;
}
body.mobile div[data-template=input_birthdate_in_japanese_calendar] input {
  width: 30px;
}

/*住所*/
/* 就学前に通っていたところの名称 */
input[type=text][data-id=former_education-name] {
  width: calc(100% - 16px);
}

/* 入学予定小学校の名前 */
/*希望する放課後児童クラブ*/
div[data-id=extended_nursery-intend_to_use] div > div:last-child {
  margin-bottom: 20px;
}
div[data-id=extended_nursery-intend_to_use]::after {
  content: "※延長を利用しない方は代理人の氏名及び電話番号の記入は不要です。";
  margin: 16px 0 32px;
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
}

div[data-id=grand_parents_info-nearby]::before {
  content: "※祖父母が近隣に居住されていない場合は記入不要です。";
  margin: 16px 0 32px;
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}
div[data-id=grand_parents_info-nearby] > h4 {
  margin-top: 30px;
}

/* 基本のインプット */
input[type=text]:focus {
  box-shadow: 0 0 6px #81bdff;
  border: solid 1px #81bdff;
  outline: none;
  padding: 3px;
  outline-offset: 0;
}
body.mobile input[type=text]:focus {
  border: solid 1px #81bdff;
}
input[type=text]:read-only {
  border: 0;
  color: #2470ff;
  box-shadow: none;
  padding: 3px;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  width: 100px;
}
body.mobile input[type=text] {
  border: solid 1px #ccc;
  -webkit-appearance: none;
  font-size: 16px;
}

/* number系*/
body.mobile input[type=number] {
  border: solid 1px #ccc;
  -webkit-appearance: none;
  font-size: 16px;
}

input[type=text] {
  border: solid thin #bfbfbf;
  padding: 3px;
}
body.mobile input[type=text] {
  line-height: 1.5;
  padding: 4px 2px;
}
input[type=text]::placeholder {
  color: #aaa;
}
input[type=text]:-ms-input-placeholder {
  color: #aaa;
}
input[type=text]::-ms-input-placeholder {
  color: #aaa;
}
input[type=text]:focus {
  box-shadow: 0 0 6px #81bdff;
  border: solid 1px #81bdff;
  outline: none;
  padding: 2px;
  outline-offset: 0;
}
body.mobile input[type=text]:focus {
  padding: 3px;
  border: solid 1px #81bdff;
}
body.mobile input[type=text] {
  border: solid 1px #ccc;
  -webkit-appearance: none;
}

textarea:focus {
  box-shadow: 0 0 6px #81bdff;
  border: solid 1px #81bdff;
  outline: none;
}
body.mobile textarea:focus {
  border: solid 1px #81bdff;
}
body.mobile textarea {
  border: solid 1px #ccc;
  -webkit-appearance: none;
}

/*テキストエリア*/
div[data-id=medical-history-description] > div,
div[data-id=reason_for_application-reason] > div {
  padding: 0;
}
div[data-id=medical-history-description] textarea,
div[data-id=reason_for_application-reason] textarea {
  width: calc(100% - 1em);
}
body.mobile div[data-id=medical-history-description] textarea,
body.mobile div[data-id=reason_for_application-reason] textarea {
  width: calc(100% - 18px);
}

/* ラジオボタンとチェックボックス */
body.desktop div[data-id=extended_nursery-intend_to_use] div[data-role=elements] div.radio_wrapper .input_wrapper {
  margin: 4px 0;
}

body.mobile div[data-role=elements] fieldset .field-checkbox {
  margin-top: 10px;
}
body.mobile div[data-role=elements] fieldset .field-checkbox input[type=checkbox] {
  display: none;
}
body.mobile div[data-role=elements] fieldset .field-checkbox input[type=checkbox] + label {
  padding: 8px 16px;
  border-radius: 8px;
  border: solid 1px #aaa;
  font-size: 16px;
}
body.mobile div[data-role=elements] fieldset .field-checkbox input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 14px;
  border: 1px solid #ccc;
  border-radius: 2px;
  vertical-align: top;
}
body.mobile div[data-role=elements] fieldset .field-checkbox input[type=checkbox]:checked + label {
  background: #F0F8FF;
  border-color: #8BC5FF;
}
div[data-role=elements] fieldset .field-checkbox input[type=checkbox]:checked + label::before {
  background-image: url("./res/check.svg");
  background-color: #0089FF;
  border: 1px solid #0089FF;
  background-size: 13px 13px;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div .input_wrapper {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div .input_wrapper {
    display: flex;
    flex-direction: row;
  }
}
body.mobile div[data-role=elements] div input[type=radio] {
  display: none;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=radio] {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=radio] {
    display: none;
  }
}
body.mobile div[data-role=elements] div input[type=radio] + label {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #aaa;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=radio] + label {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #aaa;
    flex: 1;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=radio] + label {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #aaa;
    flex: 1;
  }
}
body.mobile div[data-role=elements] div input[type=radio] + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  vertical-align: top;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=radio] + label::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
    box-sizing: border-box;
    vertical-align: top;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=radio] + label::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
    box-sizing: border-box;
    vertical-align: top;
  }
}
body.mobile div[data-role=elements] div input[type=radio]:checked + label {
  background: #F0F8FF;
  border-color: #8BC5FF;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=radio]:checked + label {
    background: #F0F8FF;
    border-color: #8BC5FF;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=radio]:checked + label {
    background: #F0F8FF;
    border-color: #8BC5FF;
  }
}
body.mobile div[data-role=elements] div input[type=radio]:checked + label::before {
  border: 6px solid #0089FF;
  box-sizing: border-box;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=radio]:checked + label::before {
    border: 6px solid #0089FF;
    box-sizing: border-box;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=radio]:checked + label::before {
    border: 6px solid #0089FF;
    box-sizing: border-box;
  }
}
body.mobile div[data-role=elements] div input[type=checkbox] {
  display: none;
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=checkbox] {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=checkbox] {
    display: none;
  }
}
div[data-role=elements] div input[type=checkbox] + label {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #aaa;
  display: block;
}
body.desktop div[data-role=elements] div input[type=checkbox] + label {
  margin: auto;
  padding: 10px;
  border: 0;
  display: inline;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=checkbox] + label {
    display: inline-block;
  }
}
body.mobile div[data-role=elements] div input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  vertical-align: top;
  border-radius: 2px;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=checkbox] + label::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 2px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=checkbox] + label::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 2px;
  }
}
body.mobile div[data-role=elements] div input[type=checkbox]:checked + label {
  background: #F0F8FF;
  border-color: #8BC5FF;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=checkbox]:checked + label {
    background: #F0F8FF;
    border-color: #8BC5FF;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=checkbox]:checked + label {
    background: #F0F8FF;
    border-color: #8BC5FF;
  }
}
body.mobile div[data-role=elements] div input[type=checkbox]:checked + label::before {
  background-image: url(res/check.svg);
  background-color: #0089ff;
  border: 1px solid #0089ff;
  background-size: 13px 13px;
  background-position: 50%;
  background-repeat: no-repeat;
}
@media screen and (orientation: landscape) {
  body.tablet div[data-role=elements] div input[type=checkbox]:checked + label::before {
    background-image: url(res/check.svg);
    background-color: #0089ff;
    border: 1px solid #0089ff;
    background-size: 13px 13px;
    background-position: 50%;
    background-repeat: no-repeat;
  }
}
@media screen and (orientation: portrait) {
  body.tablet div[data-role=elements] div input[type=checkbox]:checked + label::before {
    background-image: url(res/check.svg);
    background-color: #0089ff;
    border: 1px solid #0089ff;
    background-size: 13px 13px;
    background-position: 50%;
    background-repeat: no-repeat;
  }
}

body.mobile div[data-template=input_gender] > label > input[type=radio] {
  display: inline-block;
}

body.desktop div.field-checkbox {
  margin: 10px auto;
  padding: 0px 20px 0px 0px;
  border: 0;
  display: inline-block;
}
@media screen and (orientation: landscape) {
  body.tablet div.field-checkbox {
    display: inline-block;
    margin-right: 20px;
  }
}

/*祖父母の状況*/
div[data-id=grand_parents_info-father_of_father]::before {
  content: "父方の祖父";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=grand_parents_info-father_of_father]::before {
  font-size: 20px;
}

div[data-id=grand_parents_info-mother_of_father]::before {
  content: "父方の祖母";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=grand_parents_info-mother_of_father]::before {
  font-size: 20px;
}

div[data-id=grand_parents_info-father_of_mother]::before {
  content: "母方の祖父";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=grand_parents_info-father_of_mother]::before {
  font-size: 20px;
}

div[data-id=grand_parents_info-mother_of_mother]::before {
  content: "母方の祖母";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=grand_parents_info-mother_of_mother]::before {
  font-size: 20px;
}

/* 同居家族*/
div[data-id=family_info-family_member_info-0]::before {
  content: "同居家族 1人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-0]::before {
  font-size: 20px;
}

div[data-id=family_info-family_member_info-1]::before {
  content: "同居家族 2人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-1]::before {
  font-size: 20px;
}

div[data-id=family_info-family_member_info-2]::before {
  content: "同居家族 3人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-2]::before {
  font-size: 20px;
}

div[data-id=family_info-family_member_info-3]::before {
  content: "同居家族 4人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-3]::before {
  font-size: 20px;
}

div[data-id=family_info-family_member_info-4]::before {
  content: "同居家族 5人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-4]::before {
  font-size: 20px;
}

div[data-id=family_info-family_member_info-5]::before {
  content: "同居家族 6人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-5]::before {
  font-size: 20px;
}

div[data-id=family_info-family_member_info-6]::before {
  content: "同居家族 7人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-6]::before {
  font-size: 20px;
}

div[data-id=family_info-family_member_info-7]::before {
  content: "同居家族 8人目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=family_info-family_member_info-7]::before {
  font-size: 20px;
}

/* 就学前の状況 */
div[data-id=former_education-former_education_info-0-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その1";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-0-type]::before {
  font-size: 20px;
}

div[data-id=former_education-former_education_info-1-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その2";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-1-type]::before {
  font-size: 20px;
}

div[data-id=former_education-former_education_info-2-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その3";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-2-type]::before {
  font-size: 20px;
}

div[data-id=former_education-former_education_info-3-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その4";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-3-type]::before {
  font-size: 20px;
}

div[data-id=former_education-former_education_info-4-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その5";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-4-type]::before {
  font-size: 20px;
}

div[data-id=former_education-former_education_info-5-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その6";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-5-type]::before {
  font-size: 20px;
}

div[data-id=former_education-former_education_info-6-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その7";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-6-type]::before {
  font-size: 20px;
}

div[data-id=former_education-former_education_info-7-type]::before {
  content: "就学前に通っていた保育園・幼稚園 その8";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=former_education-former_education_info-7-type]::before {
  font-size: 20px;
}

/* 利用料の引き落としをする口座(むりやり必須をつけている) */
div[data-id=account_information-japan_post_or_not] p[data-role=description]::after {
  content: "※必須";
  color: red;
  font-size: 80%;
  font-weight: normal;
  margin-left: 10px;
}

/* 緊急連絡先*/
div[data-id=emergency-contact-1]::before {
  content: "緊急連絡先 1番目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=emergency-contact-1]::before {
  font-size: 20px;
}

div[data-id=emergency-contact-2]::before {
  content: "緊急連絡先 2番目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=emergency-contact-2]::before {
  font-size: 20px;
}

div[data-id=emergency-contact-3]::before {
  content: "緊急連絡先 3番目";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=emergency-contact-3]::before {
  font-size: 20px;
}

h4 {
  margin: 0;
  margin-bottom: 6px;
  font-size: 15px;
  color: #333;
}
body.mobile h4 {
  font-size: 16px;
}
h4[data-role=title].completed::after {
  content: "";
  display: inline-block;
  background-image: url("./res/check_green.svg");
  width: 18px;
  height: 18px;
  margin-left: 10px;
  vertical-align: top;
}

.composite_element > h4[data-role=title] {
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile .composite_element > h4[data-role=title] {
  font-size: 20px;
}

/*///////////////////////Box//////////////////////////////*/
.box {
  flex: 1;
  -ms-flex: 1;
  margin: 16px;
}
body.mobile .box {
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-bottom: solid 1px #ddd;
}
.box.disabled.closed {
  background: #eee;
  box-shadow: none;
}
.box.disabled.closed header {
  background: #eee;
  cursor: not-allowed;
}
body.desktop .box.disabled.closed header:hover {
  background: #eee;
}
.box.disabled.closed header h2 {
  color: #bbb;
}
.box.disabled.closed .num {
  background: #ddd;
}
.box.disabled.closed button.toggle {
  display: none;
}
.box .fieldWrapper {
  padding: 10px;
}
.box > .fieldWrapper {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
.box.closed {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
.box.closed .fieldWrapper {
  display: none;
}
.box > div > div {
  padding-left: 0.5em;
}
body.mobile .box > div > div {
  padding-left: 0;
}
.box.closed header {
  background: #fff;
}
.box header {
  background: #EFF2FF;
  align-items: center;
  -ms-flex-align: center;
  cursor: pointer;
}
body.desktop .box header:hover {
  background-color: #F5F7FF;
}
.box header h2 {
  margin-left: 10px;
  font-size: 100%;
  font-weight: bold;
}

.box.closed header h2 {
  font-weight: normal;
}

.box.closed .num {
  background: #bbb;
}

.num {
  background: #2470FF;
  color: #fff;
  width: 20px;
  height: 42px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
}

.button-wrapper {
  align-self: center;
  -ms-align-self: center;
}

.radio_wrapper {
  flex-wrap: wrap;
}

input#account_information-japan_post_or_not-non_japan_post + label,
input#account_information-japan_post_or_not-japan_post + label {
  flex: 1;
}

div[data-id=account_information-japan_post],
div[data-id=account_information-non_japan_post] {
  border-top: 1px solid #0884ff;
  padding-top: 14px;
  margin-top: -14px;
}

div[data-id=guardian_info-phone_number]::before {
  content: "連絡先";
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #0884ff;
  margin-bottom: 16px;
  margin-top: 16px;
}
body.mobile div[data-id=guardian_info-phone_number]::before {
  font-size: 20px;
}

div[data-template=input_map] .notice {
  margin: 16px 0;
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 10px 10px;
}
div[data-template=input_map] .notice p {
  padding: 0;
  margin: 0;
}

div.input-number input {
  width: 2em;
  text-align: center;
  font-size: 18px;
  border: 0;
  border-bottom: solid 2px #0884ff;
  border-radius: 0;
}
body.mobile div.input-number input {
  width: 3em;
  text-align: center;
  height: 36px;
  font-size: 20px;
  border: 0;
  border-radius: 0;
  border-bottom: solid 2px #0884ff;
}
body.mobile div.input-number input:focus {
  border: 0;
  border-radius: 0;
  border-bottom: solid 2px #0884ff;
  padding: 4px 2px;
}
div.input-number input:focus {
  border: 0;
  border-bottom: solid 2px #0884ff;
  box-shadow: none;
  padding: 3px;
}
div.input-number > div {
  margin-left: 20px;
}
div.input-number > div button {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 0;
  margin: 6px;
}
body.desktop div.input-number > div button:hover {
  background-color: #0884ff;
  color: #fff;
}
body.desktop div.input-number > div button[disabled]:hover {
  background-color: #fff;
  color: #bbb;
}
body.mobile div.input-number > div button {
  margin: 0 10px;
  width: 40px;
  height: 40px;
  font-weight: bold;
}

.folded[data-template=field-element] > * {
  display: none;
}

.folded[data-template=field-element] > label {
  display: block;
}

.composite_element.foldable {
  border: solid 1px #a2c7ff;
  border-radius: 6px;
  overflow: hidden;
}
.composite_element.foldable.folded > label::after {
  transform: rotate(0deg);
}
.composite_element.foldable > label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eef7ff;
}
.composite_element.foldable > label::after {
  content: "";
  display: inline-block;
  background-image: url(res/toggle.svg);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  transition: all 0.2s ease-in-out;
  padding: 0;
  margin: 10px;
  transform: rotate(90deg);
}
.composite_element.foldable > label input {
  display: none;
}
.composite_element.foldable > label > h4 {
  border: 0;
  margin-top: 0;
  padding: 10px;
  margin-bottom: 0;
  flex: 1;
}
.composite_element.foldable > div[data-role=elements] {
  padding: 10px;
}

body.mobile .operation_button_wrapper {
  display: flex;
}
.operation_button_wrapper button {
  font-size: 16px;
  margin: 0px;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #f2f9ff;
  color: #007be4;
}
body.desktop .operation_button_wrapper button {
  margin: 10px 12px 10px 0;
  font-size: 14px;
}
@media screen and (orientation: landscape) {
  body.tablet .operation_button_wrapper button {
    margin: 10px 12px 10px 0;
    font-size: 14px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet .operation_button_wrapper button {
    margin: 10px 12px 10px 0;
    font-size: 14px;
  }
}

#debug_controller {
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
#debug_controller button {
  color: #fff;
  border-color: #fff;
}
body.desktop #debug_controller button:hover {
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
}
#debug_controller button[active] {
  color: #8ce994;
  border-color: #8ce994;
}
body.desktop #debug_controller button[active]:hover {
  color: #8ce994;
  background-color: transparent;
  border-color: #8ce994;
}

.debug-info {
  font-size: 80%;
  color: #333;
  line-height: 3.5;
}

.edition-info {
  font-size: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edition-info::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 6px;
}
.edition-info.public::before {
  background-color: #2441c9;
}
.edition-info.frontdesk::before {
  background-color: #78c924;
}
.edition-info.backoffice::before {
  background-color: #ab24c9;
}

#top h1 {
  margin: 0;
  font-size: 200%;
  color: #222;
}
body.mobile #top h1 {
  font-size: 150%;
  border-bottom: 3px solid #2470ff;
  padding-bottom: 10px;
}
#top h2 {
  font-size: 190%;
  font-weight: normal;
  margin: 30px auto;
}
body.mobile #top h2 {
  margin-bottom: 20px;
}
body.mobile #top h2 + ul {
  padding-left: 0.5em;
}
#top h3 {
  font-size: 160%;
  color: #444;
}
body.mobile #top h3 {
  font-size: 140%;
}
body.mobile #top h3 + ul {
  padding-left: 0.5em;
}
#top p {
  padding: 0;
  text-indent: 1em;
}
body.mobile #top p {
  text-indent: 0;
}
#top p.summary {
  font-size: 110%;
  line-height: 1.4;
  margin-top: 30px;
  text-indent: 0;
}
#top a {
  display: block;
  font-size: 120%;
  text-align: right;
}
body.mobile #top a {
  font-size: 100%;
}
#top a.goto-form {
  width: 60%;
  background: #0089ff;
  border-radius: 10px;
  padding: 16px 8px;
  margin: 60px auto;
  text-decoration: none;
  text-align: center;
  font-size: 140%;
  font-weight: bold;
  color: #fff;
}
body.mobile #top a.goto-form {
  width: 90%;
}
#top a.goto-form.disable {
  background-color: #ccc;
}
body.desktop #top a.goto-form.disable:hover {
  background: #ccc;
}
body.desktop #top a.goto-form:hover {
  background-color: #0079e0;
}
#top fieldset {
  display: flex;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column;
}
body.mobile #top fieldset {
  padding: 0;
}
#top fieldset div {
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  margin: 10px;
  flex: 1;
  -ms-flex: 1 0 auto;
}
body.mobile #top fieldset div {
  margin: 10px 0;
}
#top fieldset label {
  display: inline-block;
}
#top fieldset.payment-type {
  border: 0;
}
#top fieldset.payment-type label {
  font-size: 130%;
}
#top section {
  margin: 80px auto;
}
body.mobile #top section {
  margin: 60px auto;
}
body.mobile #top section:first-child {
  margin-top: 0;
}
#top section:first-child {
  margin-top: 0;
}
#top section > div {
  border-radius: 8px;
  margin: 60px auto;
  padding: 20px 40px;
}
body.mobile #top section > div {
  padding: 0;
}
#top section > div h3 {
  margin-top: 10px;
}
#top .step ul {
  font-size: 120%;
  list-style: disc;
}
body.mobile #top .step ul {
  font-size: 100%;
  padding: 0 1em 0;
}
#top .step ul li {
  margin: 20px 0;
}
body.mobile #top .step h2 {
  margin-bottom: 100px;
}
#top .step > div {
  border: solid 1px #eee;
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  padding: 16px 10px;
  border-radius: 30px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
}
body.mobile #top .step > div {
  padding: 8px 5px;
  margin: 100px auto;
  display: block;
  border-radius: 10px;
}
#top .step > div > div {
  padding: 10px;
}
body.mobile #top .step > div > div {
  padding: 5px;
}
#top .step > div > div:last-child {
  flex: 1;
  -ms-flex: 1;
}
body.mobile #top .step > div > div:first-child {
  margin-top: -80px;
}
#top ul li {
  margin: 16px;
}
#top aside {
  font-weight: bold;
}
#top .copyright {
  text-align: center;
}
#top .onboard {
  width: 150px;
  height: 150px;
}
body.mobile #top .onboard {
  width: 150px;
  height: 150px;
  margin: auto;
}
#top .onboard.files {
  background: url("./res/kobe-gakudo/onboard_1.svg");
  background-repeat: no-repeat;
}
#top .onboard.printer {
  background: url("./res/kobe-gakudo/onboard_2.svg");
  background-repeat: no-repeat;
}
#top .onboard.home {
  background: url("./res/kobe-gakudo/onboard_3.svg");
  background-repeat: no-repeat;
}
#top .print_link {
  padding-left: 0;
  margin: 32px 0;
}
#top .print_link p {
  font-size: 100%;
  font-weight: bold;
  text-indent: 0;
  margin-bottom: 8px;
}
#top .print_link a {
  font-size: 100%;
  text-align: left;
  word-break: break-all;
}

/* requirement-view*/
.requirement-view h1 {
  margin: 30px 0;
  border-bottom: 3px solid #2470ff;
}
.requirement-view h2 {
  font-weight: bold;
}
.requirement-view h3 {
  border-bottom: 1px solid #2470ff;
}
.requirement-view aside {
  font-weight: bold;
  color: #333;
  padding-left: 1em;
  font-size: 90%;
}
.requirement-view section {
  margin: 40px 0;
}
body.mobile .requirement-view ul {
  padding-left: 1.5em;
}
.requirement-view ul.bank-compatible {
  list-style: decimal;
}
.requirement-view ul.bank-compatible h4 {
  font-size: 110%;
}
.requirement-view ul.bank-compatible aside {
  font-size: 80%;
  padding: 0;
}
.requirement-view ul.bank-compatible li {
  margin-bottom: 20px;
}
.requirement-view a {
  display: block;
  text-align: center;
  padding-bottom: 40px;
}

/* others-view*/
.others-view h1 {
  margin: 30px 0;
  border-bottom: 3px solid #2470ff;
}
body.mobile .others-view ul {
  padding-left: 1.5em;
}
.others-view ul a {
  display: inline-block;
  padding-bottom: 0;
}
.others-view a {
  display: block;
  text-align: center;
  padding-bottom: 40px;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;
  padding: 0;
  margin: 0;
  background-color: rgb(168, 168, 168);
  -webkit-text-size-adjust: none;
}

main {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
body.mobile main {
  height: auto;
}

@media screen and (orientation: landscape) {
  body.tablet main#main.row, body.tablet body.mobile div[data-role=elements] fieldset main#main.field-checkbox, body.mobile div[data-role=elements] fieldset body.tablet main#main.field-checkbox, body.tablet body.mobile div[data-role=elements] div main#main.input_wrapper, body.mobile div[data-role=elements] div body.tablet main#main.input_wrapper, body.tablet main#main.radio_wrapper {
    display: block;
  }
}
@media screen and (orientation: portrait) {
  body.tablet main#main.row, body.tablet body.mobile div[data-role=elements] fieldset main#main.field-checkbox, body.mobile div[data-role=elements] fieldset body.tablet main#main.field-checkbox, body.tablet body.mobile div[data-role=elements] div main#main.input_wrapper, body.mobile div[data-role=elements] div body.tablet main#main.input_wrapper, body.tablet main#main.radio_wrapper {
    display: block;
  }
}

iframe {
  width: 50vw;
  height: 100%;
  border: 0;
  display: block;
}

h1 {
  margin: 30px 20px;
}

h2 {
  margin: 0;
  color: #333;
  font-weight: normal;
  font-size: 110%;
}

textarea {
  width: 100%;
}

fieldset {
  border: 0;
  padding: 0;
}

#template {
  display: none;
}

#container {
  height: 100%;
}

.main-content {
  background: #f7f7f7;
}
.main-content main {
  height: auto;
}

@media screen and (orientation: landscape) {
  body.tablet div.generated.main-content {
    width: 100%;
    height: auto;
  }
}

.wrap {
  margin: 40px auto;
  width: 60%;
  background: #fff;
  border-radius: 10px;
  padding: 50px 60px;
}
body.mobile .wrap {
  width: 94%;
  padding: 3%;
  margin: 0;
}
@media screen and (orientation: portrait) {
  body.tablet .wrap {
    width: 75%;
  }
}
@media screen and (orientation: landscape) {
  body.tablet .wrap {
    width: 80%;
  }
}

.form-view {
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  position: relative;
  -ms-overflow-style: none;
  overflow-y: -moz-hidden-unscrollable;
  overflow-x: -moz-hidden-unscrollable;
}
.form-view::-webkit-scrollbar {
  display: none;
}
body.mobile .form-view {
  padding: 0;
  overflow: visible;
}
@media screen and (orientation: portrait) {
  body.tablet .form-view {
    overflow: visible;
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (orientation: landscape) {
  body.tablet .form-view {
    overflow: visible;
    -webkit-overflow-scrolling: touch;
  }
}

.application-window {
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  overflow-y: -moz-hidden-unscrollable;
  overflow-x: -moz-hidden-unscrollable;
}
.application-window::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 800px) {
  body.desktop .application-window {
    display: none;
  }
}
body.mobile .application-window {
  display: none;
}
@media screen and (orientation: portrait) {
  body.tablet .application-window {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet .application-window {
    display: none;
  }
}

@keyframes scrollme {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.notification {
  width: 540px;
  position: fixed;
  z-index: 99999;
  top: calc(50% - 80px);
  left: calc(50% - 270px);
  border: solid 1px #eee;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #fff;
}
body.mobile .notification {
  width: 80%;
  top: calc(50% - 80px);
  left: 10%;
  border-radius: 6px;
}
.notification p {
  padding: 10px 16px;
}
.notification > div {
  position: relative;
}
.notification canvas.scrollable {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  right: 2px;
  top: 10px;
}
.notification.alert2 {
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
body.mobile .notification.alert2 {
  top: 50%;
  transform: translateY(-50%);
}
.notification.alert2 div {
  overflow-y: scroll;
}
.notification.alert2 div::-webkit-scrollbar {
  display: none;
}
.notification.alert2 div img {
  display: block;
  width: 100%;
}
.notification.alert2 button {
  flex: none;
}
.notification.unsupported {
  top: calc(50% - 165px);
}
body.mobile .notification.unsupported {
  top: calc(50% - 200px);
}
.notification.howto {
  top: 15%;
  left: 0;
  width: 60%;
  margin: 0 20%;
  border: 0;
  height: 70%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
body.mobile .notification.howto {
  width: 94%;
  height: 94%;
  left: 3%;
  top: 3%;
  margin: 0;
}
.notification.howto img {
  width: 60%;
  display: block;
  margin: auto;
}
.notification.howto h1 {
  margin: 0;
  font-size: 17px;
  background-color: #2970ff;
  color: #fff;
  padding: 10px;
  text-align: center;
}
body.mobile .notification.howto h1 {
  font-size: 16px;
}
.notification.howto h2 {
  padding-left: 1em;
  clear: both;
  font-size: 18px;
  padding: 10px 0;
  font-size: 14px;
  background-color: #def0ff;
  margin: 0;
  /* color: #fff; */
  font-weight: bold;
}
body.mobile .notification.howto h2 {
  font-size: 14px;
}
.notification.howto p {
  padding: 0;
  margin: 0;
  flex: 1;
  overflow: scroll;
}
.notification.howto span {
  display: block;
  margin: 10px;
}
body.mobile .notification.howto span {
  font-size: 14px;
}
.notification.howto button {
  flex: none;
}
.notification.confirm > div {
  height: 44px;
}
body.mobile .notification.confirm button {
  padding: 0;
  margin: 0;
}
body.mobile .notification.confirm button.cancelButton {
  border-right: solid 1px #ccc;
}
.notification button {
  flex: 1;
  -ms-flex: 1;
  display: block;
  text-align: center;
  color: #0089ff;
  text-decoration: none;
  border: none;
  border-radius: 0;
  border-top: solid 1px #ccc;
  width: 100%;
  padding: 10px 0 12px;
  margin: 0;
}
body.mobile .notification button {
  margin: 0;
  padding: 12px 0;
}
body.desktop .notification button:hover {
  background-color: #F0F8FF;
  color: #0089ff;
}

.lightbox {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99998;
  position: fixed;
  top: 0;
}

*[debug] {
  display: none;
}

.scaling_button_wrapper {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  width: 160px;
}
body.mobile .scaling_button_wrapper {
  display: none;
}
@media screen and (orientation: portrait) {
  body.tablet .scaling_button_wrapper {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet .scaling_button_wrapper {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  body.desktop .scaling_button_wrapper {
    display: none;
  }
}
.scaling_button_wrapper div {
  user-select: none;
  -webkit-user-select: none;
  padding: 10px;
  text-align: center;
  flex: 1;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #0089ff;
  color: #0089ff;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
}
body.desktop .scaling_button_wrapper div:hover {
  background-color: #0089ff;
  color: #fff;
}

#zoom {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #0089ff;
}

#shrink {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.notice {
  margin: 20px 20px 10px;
  background-color: #eff2fe;
  color: #3a61ff;
  border: solid 1px #c3cffe;
  border-radius: 4px;
  padding: 10px 10px;
}
.notice p {
  margin: 0;
}

footer {
  display: flex;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  justify-content: center;
  -ms-flex-pack: center;
}
body.mobile footer {
  position: relative;
  bottom: auto;
  box-shadow: none;
  background: transparent;
  display: block;
}
footer p {
  margin: 0;
}

.footer-controlls {
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
}
.footer-controlls:first-child {
  flex: 1;
  -ms-flex: 1 0 auto;
}
.footer-controlls.control-main {
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
}
body.mobile .footer-controlls.control-main {
  display: block;
}
body.mobile .footer-controlls.control-iframe {
  display: none;
}
@media screen and (orientation: portrait) {
  body.tablet .footer-controlls.control-iframe {
    display: none;
  }
}

/*PC版でQR読み込みをふさぐ*/
button {
  border: 1px solid #0089ff;
  color: #0089ff;
  /* font-size: 100%; */
  background: transparent;
  border-radius: 5px;
  padding: 2px 12px;
  margin: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  touch-action: manipulation;
}
body.mobile button {
  display: block;
  margin: 10px;
}
button.menu {
  display: none;
}
body.mobile button.menu {
  background-image: url("./res/menu.svg");
  width: 24px;
  height: 24px;
  border: 0;
  display: block;
}
button:focus {
  outline: none;
}
body.desktop button:hover {
  background-color: #0089ff;
  color: #fff;
}
body.mobile button.read-qr {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/read-qr@2x.png);
  background-size: 40px 40px;
}
body.desktop button.read-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop button.read-qr::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/read-qr@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet button.read-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet button.read-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet button.read-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet button.read-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr@2x.png);
    background-size: 24px 24px;
  }
}
body.desktop button.read-qr:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/read-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet button.read-qr:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet button.read-qr:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
body.mobile button.show-qr {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/show-qr@2x.png);
  background-size: 40px 40px;
}
body.desktop button.show-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop button.show-qr::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet button.show-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet button.show-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet button.show-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet button.show-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
body.desktop button.show-qr:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet button.show-qr:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet button.show-qr:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
body.mobile button.save-pdf {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 40px 40px;
}
body.desktop button.save-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop button.save-pdf::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet button.save-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet button.save-pdf::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet button.save-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet button.save-pdf::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 24px 24px;
  }
}
body.desktop button.save-pdf:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet button.save-pdf:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf_hover@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet button.save-pdf:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf_hover@2x.png);
    background-size: 24px 24px;
  }
}
button.preview {
  color: #0089ff;
}
button.preview::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("./res/check.svg");
  background-repeat: no-repeat;
  margin-right: 8px;
}
button.preview.on {
  background-color: #0089ff;
  color: #fff;
}
body.desktop button.preview:hover {
  background-color: #0079e0;
}
button.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("./res/toggle.svg");
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  font-weight: bold;
  transition: 0.2s ease-in-out all;
  padding: 0;
}
body.desktop button.toggle:hover {
  background-color: #EFF2FF;
}
button.toggle.opened {
  transform: rotateZ(90deg);
}
body.mobile button.toggle {
  margin-right: 16px;
}
button[disabled] {
  color: #bbb;
  border-color: #bbb;
  cursor: not-allowed;
}
body.desktop button[disabled]:hover {
  background: #fff;
  color: #bbb;
}
button[debug] {
  background-color: #a628c5;
  color: #fff;
  border: 0;
}
body.mobile button[debug] {
  display: none;
}

footer button {
  width: 60%;
  margin: 16px auto;
  padding: 6px 12px;
}
body.mobile footer button {
  width: 90%;
  margin: 20px auto;
}
body.mobile footer button.read-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 90%;
  height: auto;
  text-indent: 0;
  margin: 20px auto;
  padding: 6px 12px;
  border: 1px solid #0089ff;
  border-radius: 5px;
  background-color: #4692ff;
  background-image: none;
}
body.mobile footer button.read-qr::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/read-qr_hover@2x.png);
  background-size: 24px 24px;
}
body.desktop footer button.read-qr {
  color: #fff;
  width: 60%;
  margin: 16px auto;
  padding: 6px 12px;
  border: 1px solid #0089ff;
  border-radius: 5px;
  background-color: #4692ff;
}
body.desktop footer button.read-qr::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/read-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet footer button.read-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 90%;
    height: auto;
    text-indent: 0;
    margin: 20px auto;
    padding: 6px 12px;
    border: 1px solid #0089ff;
    border-radius: 5px;
    background-color: #4692ff;
    background-image: none;
    width: 60%;
  }
  body.tablet footer button.read-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet footer button.read-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 90%;
    height: auto;
    text-indent: 0;
    margin: 20px auto;
    padding: 6px 12px;
    border: 1px solid #0089ff;
    border-radius: 5px;
    background-color: #4692ff;
    background-image: none;
    width: 60%;
  }
  body.tablet footer button.read-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
body.mobile footer button.show-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 90%;
  height: auto;
  text-indent: 0;
  margin: 20px auto;
  padding: 6px 12px;
  border: 1px solid #0089ff;
  border-radius: 5px;
  background-color: #4692ff;
  background-image: none;
}
body.mobile footer button.show-qr::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr_hover@2x.png);
  background-size: 24px 24px;
}
body.desktop footer button.show-qr {
  color: #fff;
  width: 60%;
  margin: 16px auto;
  padding: 6px 12px;
  border: 1px solid #0089ff;
  border-radius: 5px;
  background-color: #4692ff;
}
body.desktop footer button.show-qr::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet footer button.show-qr {
    color: #fff;
    width: 60%;
    margin: 16px auto;
    padding: 6px 12px;
    border: 1px solid #0089ff;
    border-radius: 5px;
    background-color: #4692ff;
    width: 60%;
  }
  body.tablet footer button.show-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet footer button.show-qr {
    color: #fff;
    width: 60%;
    margin: 16px auto;
    padding: 6px 12px;
    border: 1px solid #0089ff;
    border-radius: 5px;
    background-color: #4692ff;
    width: 60%;
  }
  body.tablet footer button.show-qr::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
body.mobile footer button.save-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 90%;
  height: auto;
  text-indent: 0;
  margin: 20px auto;
  padding: 6px 12px;
  border: 1px solid #0089ff;
  border-radius: 5px;
  background-color: #4692ff;
  background-image: none;
}
body.mobile footer button.save-pdf::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf_hover@2x.png);
  background-size: 24px 24px;
}
body.desktop footer button.save-pdf {
  color: #fff;
  width: 60%;
  margin: 16px auto;
  padding: 6px 12px;
  border: 1px solid #0089ff;
  border-radius: 5px;
  background-color: #4692ff;
}
body.desktop footer button.save-pdf::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet footer button.save-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 90%;
    height: auto;
    text-indent: 0;
    margin: 20px auto;
    padding: 6px 12px;
    border: 1px solid #0089ff;
    border-radius: 5px;
    background-color: #4692ff;
    background-image: none;
    width: 60%;
  }
  body.tablet footer button.save-pdf::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf_hover@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet footer button.save-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 90%;
    height: auto;
    text-indent: 0;
    margin: 20px auto;
    padding: 6px 12px;
    border: 1px solid #0089ff;
    border-radius: 5px;
    background-color: #4692ff;
    background-image: none;
    width: 60%;
  }
  body.tablet footer button.save-pdf::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf_hover@2x.png);
    background-size: 24px 24px;
  }
}

header button.runtime.read-qr-camera {
  display: inline-block;
}
body.mobile header button.runtime.read-qr-camera.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.read-qr-camera.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/read-qr@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.read-qr-camera.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.read-qr-camera.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/read-qr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.read-qr-camera.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.read-qr-camera.dk-text::before {
  display: none;
}
body.desktop header button.runtime.read-qr-camera.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/read-qr@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.read-qr-camera.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.read-qr-camera.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/read-qr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.read-qr-camera.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/read-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-camera.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-camera.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-camera.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/read-qr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-camera.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.read-qr-camera.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-camera.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-camera.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-camera.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/read-qr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-camera.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.read-qr-camera.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/read-qr@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.show-qr {
  display: inline-block;
}
body.mobile header button.runtime.show-qr.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.show-qr.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/show-qr@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.show-qr.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.show-qr.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.show-qr.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.show-qr.dk-text::before {
  display: none;
}
body.desktop header button.runtime.show-qr.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/show-qr@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.show-qr.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.show-qr.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.show-qr.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/show-qr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.show-qr.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/show-qr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.show-qr.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.save-pdf {
  display: inline-block;
}
body.mobile header button.runtime.save-pdf.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.save-pdf.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.save-pdf.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.save-pdf.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.save-pdf.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.save-pdf.dk-text::before {
  display: none;
}
body.desktop header button.runtime.save-pdf.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.save-pdf.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.save-pdf.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.save-pdf.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.save-pdf.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.save-pdf.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.save-pdf-noqr {
  display: inline-block;
}
body.mobile header button.runtime.save-pdf-noqr.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.save-pdf-noqr.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.save-pdf-noqr.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.save-pdf-noqr.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.save-pdf-noqr.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.save-pdf-noqr.dk-text::before {
  display: none;
}
body.desktop header button.runtime.save-pdf-noqr.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.save-pdf-noqr.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.save-pdf-noqr.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.save-pdf-noqr.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-pdf_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf-noqr.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf-noqr.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf-noqr.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-pdf-noqr.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.save-pdf-noqr.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf-noqr.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf-noqr.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf-noqr.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-pdf-noqr.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.save-pdf-noqr.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-pdf@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.print-qr {
  display: inline-block;
}
body.mobile header button.runtime.print-qr.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.print-qr.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/qr_print@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.print-qr.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.print-qr.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/qr_print@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-qr.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.print-qr.dk-text::before {
  display: none;
}
body.desktop header button.runtime.print-qr.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/qr_print@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.print-qr.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.print-qr.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/qr_print@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-qr.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/qr_print_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-qr.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-qr.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-qr.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/qr_print@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-qr.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-qr.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/qr_print@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-qr.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-qr.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-qr.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/qr_print@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-qr.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-qr.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/qr_print@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.print-pdf-noqr {
  display: inline-block;
}
body.mobile header button.runtime.print-pdf-noqr.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.print-pdf-noqr.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/form_print@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.print-pdf-noqr.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.print-pdf-noqr.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/form_print@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-pdf-noqr.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.print-pdf-noqr.dk-text::before {
  display: none;
}
body.desktop header button.runtime.print-pdf-noqr.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/form_print@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.print-pdf-noqr.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.print-pdf-noqr.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/form_print@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-pdf-noqr.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/form_print_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf-noqr.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf-noqr.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf-noqr.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/form_print@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf-noqr.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-pdf-noqr.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/form_print@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf-noqr.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf-noqr.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf-noqr.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/form_print@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf-noqr.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-pdf-noqr.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/form_print@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.print-pdf {
  display: inline-block;
}
body.mobile header button.runtime.print-pdf.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.print-pdf.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/form_qr_print@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.print-pdf.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.print-pdf.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/form_qr_print@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-pdf.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.print-pdf.dk-text::before {
  display: none;
}
body.desktop header button.runtime.print-pdf.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/form_qr_print@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.print-pdf.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.print-pdf.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/form_qr_print@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-pdf.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/form_qr_print_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/form_qr_print@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-pdf.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-pdf.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/form_qr_print@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/form_qr_print@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-pdf.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-pdf.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/form_qr_print@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.show-qr-single {
  display: inline-block;
}
body.mobile header button.runtime.show-qr-single.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.show-qr-single.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/show-qr@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.show-qr-single.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.show-qr-single.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.show-qr-single.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.show-qr-single.dk-text::before {
  display: none;
}
body.desktop header button.runtime.show-qr-single.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/show-qr@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.show-qr-single.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.show-qr-single.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.show-qr-single.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr-single.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr-single.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr-single.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/show-qr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.show-qr-single.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.show-qr-single.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr-single.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr-single.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr-single.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/show-qr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.show-qr-single.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.show-qr-single.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.read-qr-image {
  display: inline-block;
}
body.mobile header button.runtime.read-qr-image.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.read-qr-image.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/photo-library@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.read-qr-image.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.read-qr-image.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/photo-library@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.read-qr-image.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.read-qr-image.dk-text::before {
  display: none;
}
body.desktop header button.runtime.read-qr-image.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/photo-library@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.read-qr-image.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.read-qr-image.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/photo-library@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.read-qr-image.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/photo-library_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-image.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-image.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-image.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/photo-library@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.read-qr-image.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.read-qr-image.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/photo-library@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-image.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-image.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-image.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/photo-library@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.read-qr-image.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.read-qr-image.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/photo-library@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.write-signature {
  display: inline-block;
}
body.mobile header button.runtime.write-signature.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.write-signature.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/write-signature@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.write-signature.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.write-signature.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/write-signature@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.write-signature.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.write-signature.dk-text::before {
  display: none;
}
body.desktop header button.runtime.write-signature.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/write-signature@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.write-signature.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.write-signature.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/write-signature@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.write-signature.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/write-signature_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.write-signature.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.write-signature.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.write-signature.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/write-signature@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.write-signature.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.write-signature.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/write-signature@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.write-signature.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.write-signature.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.write-signature.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/write-signature@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.write-signature.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.write-signature.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/write-signature@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.save-csvqr {
  display: inline-block;
}
body.mobile header button.runtime.save-csvqr.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.save-csvqr.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/save-csvqr@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.save-csvqr.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.save-csvqr.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-csvqr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.save-csvqr.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.save-csvqr.dk-text::before {
  display: none;
}
body.desktop header button.runtime.save-csvqr.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/save-csvqr@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.save-csvqr.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.save-csvqr.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-csvqr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.save-csvqr.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/save-csvqr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-csvqr.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-csvqr.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-csvqr.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/save-csvqr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.save-csvqr.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.save-csvqr.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-csvqr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-csvqr.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-csvqr.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-csvqr.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/save-csvqr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.save-csvqr.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.save-csvqr.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/save-csvqr@2x.png);
    background-size: 24px 24px;
  }
}
header button.runtime.print-csvqr {
  display: inline-block;
}
body.mobile header button.runtime.print-csvqr.mb-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
}
body.mobile header button.runtime.print-csvqr.mb-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/print-csvqr@2x.png);
  background-size: 40px 40px;
}
body.mobile header button.runtime.print-csvqr.mb-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.mobile header button.runtime.print-csvqr.mb-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/print-csvqr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-csvqr.dk-text {
  width: auto;
  height: auto;
  text-indent: 0;
  border: 1px solid;
  background-image: none;
  margin: 10px;
  padding: 2px 12px;
  border-radius: 5px;
}
body.desktop header button.runtime.print-csvqr.dk-text::before {
  display: none;
}
body.desktop header button.runtime.print-csvqr.dk-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/print-csvqr@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.runtime.print-csvqr.dk-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.runtime.print-csvqr.dk-icon-text::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/print-csvqr@2x.png);
  background-size: 24px 24px;
}
body.desktop header button.runtime.print-csvqr.dk-icon-text:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/print-csvqr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-csvqr.tl-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-csvqr.tl-text::before {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-csvqr.tl-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/print-csvqr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: landscape) {
  body.tablet header button.runtime.print-csvqr.tl-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-csvqr.tl-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/print-csvqr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-csvqr.tp-text {
    width: auto;
    height: auto;
    text-indent: 0;
    border: 1px solid;
    background-image: none;
    border-radius: 5px;
    padding: 2px 12px;
    margin: 10px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-csvqr.tp-text::before {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-csvqr.tp-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    background-image: url(./res/print-csvqr@2x.png);
    background-size: 40px 40px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.runtime.print-csvqr.tp-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.runtime.print-csvqr.tp-icon-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/print-csvqr@2x.png);
    background-size: 24px 24px;
  }
}

#top input[type=checkbox] {
  display: none;
}
#top input[type=checkbox] + label {
  padding: 16px 16px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #aaa;
  font-size: 120%;
  cursor: pointer;
}
#top input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 14px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
#top input[type=checkbox]:checked + label {
  background: #F0F8FF;
  border-color: #8BC5FF;
}
#top input[type=checkbox]:checked + label::before {
  background-image: url("./res/check.svg");
  background-color: #0089FF;
  border: 1px solid #0089FF;
  background-size: 13px 13px;
  background-position: center;
  background-repeat: no-repeat;
}
footer input[type=checkbox] {
  display: none;
}
footer input[type=checkbox] + label {
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  padding: 5px 28px 5px 10px;
  border-radius: 8px;
  border: solid 1px #0089ff;
  height: 18px;
  margin: 10px;
  color: #0089ff;
  line-height: 1.15;
}
footer input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
footer input[type=checkbox]:checked + label {
  background: #0089ff;
  border-color: #0089ff;
  color: #fff;
}
footer input[type=checkbox]:checked + label::before {
  background-image: url("./res/check.svg");
  background-repeat: no-repeat;
}

#top input[type=radio] {
  display: none;
}
#top input[type=radio] + label {
  padding: 16px 16px;
  border-radius: 8px;
  border: 1px solid #aaa;
  width: 100%;
  cursor: pointer;
}
#top input[type=radio] + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 14px;
  box-sizing: border-box;
}
#top input[type=radio]:checked + label {
  background: #F0F8FF;
  border-color: #8BC5FF;
}
#top input[type=radio]:checked + label::before {
  border: 6px solid #0089FF;
  box-sizing: border-box;
}
.generated input[type=radio] {
  margin-left: 10px;
}
.generated input[type=radio] + label {
  margin-left: 6px;
  margin-right: 15px;
}

.generated input[type=text] + label {
  margin-left: 6px;
}

label + input {
  margin-left: 5px;
}

input[type=text].error {
  background-color: #fffde9;
}

input.fullwidth {
  width: calc(100% - 0.5em - 5px);
}

.select-wrap {
  position: relative;
}

@media screen and (orientation: landscape) {
  body.tablet select {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: middle;
    background-color: #f0f7ff;
    flex: 1;
    height: 36px;
    padding: 0 30px;
    font-size: 110%;
    font-weight: 700;
    /* box-shadow: 0 3px 6px rgba(0,0,0,.16); */
    border: 1px solid #bbbbbb;
    border-radius: 12px;
    margin: 13px;
    color: #333;
  }
}
@media screen and (orientation: portrait) {
  body.tablet select {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: middle;
    background-color: #f0f7ff;
    flex: 1;
    height: 36px;
    padding: 0 30px;
    font-size: 110%;
    font-weight: 700;
    /* box-shadow: 0 3px 6px rgba(0,0,0,.16); */
    border: 1px solid #bbbbbb;
    border-radius: 12px;
    margin: 13px;
    color: #333;
  }
}
body.mobile select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  background-color: #f0f7ff;
  flex: 1;
  height: 36px;
  padding: 0 30px;
  font-size: 110%;
  font-weight: 700;
  /* box-shadow: 0 3px 6px rgba(0,0,0,.16); */
  border: 1px solid #bbbbbb;
  border-radius: 12px;
  margin: 13px;
  color: #333;
}

@media screen and (orientation: landscape) {
  body.tablet .select-wrap::before {
    z-index: 1;
    position: absolute;
    right: 15px;
    top: 14px;
    display: block;
    content: "";
    background-image: url("/img/sankaku_2@2x.png");
    background-size: 12px 12px;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
}
@media screen and (orientation: portrait) {
  body.tablet .select-wrap::before {
    z-index: 1;
    position: absolute;
    right: 15px;
    top: 14px;
    display: block;
    content: "";
    background-image: url("/img/sankaku_2@2x.png");
    background-size: 12px 12px;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
}
body.mobile .select-wrap::before {
  z-index: 1;
  position: absolute;
  right: 15px;
  top: 14px;
  display: block;
  content: "";
  background-image: url("/img/sankaku_2@2x.png");
  background-size: 12px 12px;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  pointer-events: none;
}

@media screen and (orientation: landscape) {
  body.tablet select.error {
    background-color: #fffde9;
  }
}
@media screen and (orientation: portrait) {
  body.tablet select.error {
    background-color: #fffde9;
  }
}
body.mobile select.error {
  background-color: #fffde9;
}

.pdf-preview-window {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  z-index: 10000;
}
body.mobile .pdf-preview-window {
  background-color: rgba(0, 0, 0, 0.7);
}
.pdf-preview-window > .pdf-preview-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  justify-content: flex-end;
  -ms-flex-pack: end;
}
.pdf-preview-window > .pdf-preview-menu.hidden {
  display: none;
}
.pdf-preview-window > .pdf-progress {
  background-color: #ffffff;
  border-radius: 4px;
  width: 50%;
}
body.mobile .pdf-preview-window > .pdf-progress {
  width: 80%;
}
.pdf-preview-window > .pdf-progress .progress-wrap {
  padding: 20px;
}
.pdf-preview-window > .pdf-progress .progress-bar-wrap {
  height: 10px;
  border-radius: 4px;
  overflow: hidden;
  background: #ccc;
  margin-top: 10px;
}
.pdf-preview-window > .pdf-preview {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  -ms-overflow-style: none;
  overflow-y: -moz-hidden-unscrollable;
  overflow-x: -moz-hidden-unscrollable;
}
.pdf-preview-window > .pdf-preview::-webkit-scrollbar {
  display: none;
}
body.mobile .pdf-preview-window > .pdf-preview {
  width: 96%;
  -webkit-overflow-scrolling: touch;
}
.pdf-preview-window > .pdf-preview > iframe {
  width: 100%;
  height: 100%;
}
.pdf-preview-window.print-mode > .pdf-preview-menu {
  display: none;
}
.pdf-preview-window.print-mode > .pdf-preview {
  display: none;
}
@media print {
  .pdf-preview-window.print-mode > .pdf-preview {
    display: block;
  }
}

.pdf-progress-bar {
  width: 0;
  height: 100%;
  transition: width 0 linear 0;
  background: linear-gradient(45deg, #38A8FA, #374dee, #0089ff);
  background-size: 600% 600%;
  animation: MoveGradient 0.5s ease-in-out infinite;
}

@keyframes MoveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  background-image: url("./res/spinner.svg");
  background-repeat: no-repeat;
  animation: spin 2s linear infinite;
}

.pdf-progress-cancel {
  flex: 1;
  -ms-flex: 1 0 auto;
  display: block;
  text-align: center;
  color: #0089ff;
  text-decoration: none;
  border-top: solid 1px #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  padding: 10px 0;
}
body.desktop .pdf-progress-cancel:hover {
  background-color: #F0F8FF;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.qr-reading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.qr-reading .qr-description {
  position: absolute;
  margin: auto;
  width: 100%;
  top: 50px;
  display: flex;
  justify-content: center;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
}
.qr-reading .qr-description p {
  text-align: center;
  color: #fff;
  background-color: #000;
  padding: 4px 12px;
  border-radius: 10px;
  font-size: 12px;
  display: inline-block;
}
.qr-reading header {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  height: 48px;
  align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -ms-flex-pack: justify;
}
.qr-reading header h1 {
  font-size: 14px;
  font-weight: normal;
}
.qr-reading header button {
  justify-content: flex-end;
}
.qr-reading video {
  display: block;
  margin: auto;
}
body.mobile .qr-reading video {
  width: 100%;
}
@media screen and (orientation: portrait) {
  body.tablet .qr-reading video {
    width: 100%;
  }
}
@media screen and (orientation: landscape) {
  body.tablet .qr-reading video {
    transform-origin: center;
  }
}

.signature-window {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  z-index: 10000;
}
body.mobile .signature-window {
  background-color: rgba(0, 0, 0, 0.7);
}
.signature-window > .signature-window-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  justify-content: flex-end;
  -ms-flex-pack: end;
}
.signature-window > .signature-window-menu.hidden {
  display: none;
}
.signature-window > .signature-window-content {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  width: 100%;
  height: 100%;
}
.signature-window > .signature-window-content > .signature-window-canvas {
  background-color: #ffffff;
  /* border: 5px solid rgba(255, 0, 222, 0.2); */
  background-color: #fff;
  box-shadow: 0 0 0 6px rgba(0, 77, 255, 0.32);
  border-radius: 3px;
}
/*!*************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./apps/yokosuka/src/styles/main.scss ***!
  \*************************************************************************************************************************************************************************************/
@charset "UTF-8";
/*

ブレイクポイントのメモ

* モバイル横は考慮しない
* 基本的スタイルはモバイル、タブレット、PCの3種類。
* iPad縦持ちはモバイルあつかい。

* モバイル =>アナログなし
* タブレット縦 => アナログなし
* タブレット横 => アナログあり
* PC => アナログあり

参考
https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
*/
div#field_element-register_phone_field::after {
  content: "申請書に登録する印鑑の押印が必要ですので、忘れずにご用意ください。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
}

div#field_element-agent_info > .fieldWrapper::before {
  content: "代理人申請は委任状の提出が必要です。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
}

div#field_element-applicant_info > .fieldWrapper::after {
  content: "代理人申請は委任状の提出が必要です。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
}

div#field_element-applicant_box > .fieldWrapper::before {
  content: "代理人申請は委任状の提出が必要です。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
}

div#field_element-guarantor_phone::after {
  content: "手続きを行う際に保証人の登録済み印鑑の押印も必要ですので、忘れずにご用意ください。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

div#field_element-sr_applicant_info > .fieldWrapper::before {
  content: "代理人申請は委任状の提出が必要です。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

div#field_element-m_agent_info > .fieldWrapper::before {
  content: "代理人申請は委任状の提出が必要です。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
}

div#field_element-rc_applicant_box > .fieldWrapper::before {
  content: "代理人申請は委任状の提出が必要です。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
}

div#field_element-pmc_child_info > .fieldWrapper::before {
  content: "小児医療証の対象となるのは中学生以下のお子さんになります。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
}

div#field_element-sr_guarantor_info > .fieldWrapper::before {
  content: "横須賀市で印鑑登録を済ませている方に限ります。";
  background-color: #ffefb7;
  color: #db6a00;
  border: solid 1px #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
}

body.mobile header button.show-qr-single {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  background-image: url(./res/show-qr@2x.png);
  background-size: 40px 40px;
}
body.desktop header button.show-qr-single {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 16px 0 10px;
  border-radius: 0;
  border: 0;
}
body.desktop header button.show-qr-single::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.show-qr-single {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.show-qr-single::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.show-qr-single {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 10px;
    border-radius: 0;
    border: 0;
  }
  body.tablet header button.show-qr-single::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr@2x.png);
    background-size: 24px 24px;
  }
}
body.desktop header button.show-qr-single:hover::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
  background-image: url(./res/show-qr_hover@2x.png);
  background-size: 24px 24px;
}
@media screen and (orientation: landscape) {
  body.tablet header button.show-qr-single:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}
@media screen and (orientation: portrait) {
  body.tablet header button.show-qr-single:hover::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
    background-image: url(./res/show-qr_hover@2x.png);
    background-size: 24px 24px;
  }
}

input[name$=_building] {
  width: calc(100% - 0.5em - 5px);
}

input[name$=_chouban] {
  width: calc(100% - 0.5em - 5px);
}

input[name^=old_setai_relation_] {
  width: calc(100% - 0.5em - 5px);
}

input[name^=new_setai_relation_] {
  width: calc(100% - 0.5em - 5px);
}

input[name^=mover_relation_] {
  width: calc(100% - 0.5em - 5px);
}

input[name^=child_zokugara_other_] {
  width: calc(100% - 0.5em - 5px);
}

input[name=new_address_building_admin] {
  width: calc(100% - 0.5em - 5px);
}

input[name=old_address_building_admin] {
  width: calc(100% - 0.5em - 5px);
}

div[data-template=field-element][id^=field_element-status_check_] {
  display: flex;
  flex-direction: row;
}
div[data-template=field-element][id^=field_element-status_check_] h4 {
  width: 70px;
}

div#field_element-lived_since div[data-role=elements] div[data-template=input_date_in_japanese_calendar]::after {
  content: "から";
}

div#field_element-lived_to div[data-role=elements] div[data-template=input_date_in_japanese_calendar]::after {
  content: "まで";
}

div.force-hidden {
  display: none;
}

span[class^=condition-] {
  display: none;
}

span[class^=condition-].show {
  display: inline;
}

.menu {
  position: fixed;
  top: 48px;
  right: 0px;
  display: none;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 25%;
  border-bottom-left-radius: 10px;
}
body.mobile .menu {
  width: 100%;
  border-bottom-left-radius: 0;
}
.menu .menu-header {
  background-color: #EFF2FD;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
.menu ul.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu ul.menu-list li {
  border-bottom: solid 1px #ccc;
}
body.desktop .menu ul.menu-list li:hover {
  background-color: #f5f7ff;
}
.menu ul.menu-list li:last-child, .menu ul.menu-list li.last-item {
  border: 0;
  border-bottom-left-radius: 10px;
}
.menu ul.menu-list li a {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu ul.menu-list li a div {
  display: flex;
  flex-direction: column;
  margin: 10px;
  flex: 1;
}
.menu ul.menu-list li a div p.header {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin: 4px 0;
}
.menu ul.menu-list li a div p.content {
  font-weight: normal;
  font-size: 12px;
  color: #444;
  margin: 4px 0;
}
.menu ul.menu-list li a.show-qr-single::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/qr_image@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.show-qr::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/qr_pdf@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.save-pdf::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/form_qr_pdf@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.save-pdf-noqr::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/form_pdf@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.print-qr::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/qr_print@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.print-pdf-noqr::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/form_print@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.print-pdf::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/form_qr_print@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.read-qr-camera::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/read-qr@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.read-qr-image::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/photo-library@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.show-howto-download p {
  color: #3075ff;
  text-decoration: underline;
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 6px 20px 0;
  font-size: 14px;
}
.menu ul.menu-list li a.show-howto-download p::before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url(res/help@2x.png);
  background-size: 17px 17px;
  background-repeat: no-repeat;
  background-position: center;
}
.menu ul.menu-list li a.write-signature::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/write_signature@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.save-csvqr::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/save-csvqr@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}
.menu ul.menu-list li a.print-csvqr::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("./res/print-csvqr@2x.png");
  background-size: 40px 40px;
  margin: 0 10px;
}

.menu.show {
  display: block;
}

[debug].menu.show p.content {
  display: none;
}

.menu label {
  display: block;
}

.menu-bar {
  display: none;
  height: 48px;
  align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -ms-flex-pack: justify;
}

.scaling_button_wrapper {
  width: auto;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  overflow: hidden;
}

div#zoom {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border: 0;
  color: #3261ff;
  font-size: 12px;
  padding: 6px 10px;
  box-shadow: none;
  border-radius: 0;
}

div#zoom:hover {
  background-color: #d9e7ff;
}

div#shrink:hover {
  background-color: #d9e7ff;
}

div#shrink {
  border-radius: 0;
  border: 0;
  color: #3261ff;
  border-left: 1px solid #c6d3fe;
  font-size: 12px;
  padding: 6px 10px;
  box-shadow: none;
}

div#zoom::before {
  content: "";
  display: block;
  width: 36px;
  height: 36px;
  background-image: url("./res/zoom@2x.png");
  background-size: 36px 36px;
  margin: 0 10px;
}

div#shrink::before {
  content: "";
  display: block;
  width: 36px;
  height: 36px;
  background-image: url("./res/shrink@2x.png");
  background-size: 36px 36px;
  margin: 0 10px;
}

p.injection-alert2 {
  background-color: #ffefb7;
  color: #db6a00;
  border: 1px solid #ffcb60;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  display: block;
}

div[id$=_admin].box > header {
  background-color: #ffe7f0;
}
div[id$=_admin].box > header div.num {
  background-color: #c74a78;
}
div[id$=_admin].box > header button.toggle {
  background-image: url(res/toggle_red.svg);
}

body.desktop div[id$=_admin].box header:hover {
  background-color: #ffbed6;
}

body.desktop div[id$=_admin].box button.toggle:hover {
  background-color: #ffbed6;
}
/*!***********************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./apps/yokosuka-frontdesk/src/styles/main.scss ***!
  \***********************************************************************************************************************************************************************************************/

